import zigni_with_rice from '../images/Menu_Images/Main_Dishes/zigni_with_rice.jpeg'
import zigni_with_white_lahloh from '../images/Menu_Images/Main_Dishes/zigni_with_white_lahloh.jpeg'
import zigni_with_blue_lahloh from '../images/Menu_Images/Main_Dishes/zigni_with_blue_lahloh.jpeg'
import White_moqalqal from '../images/Menu_Images/Main_Dishes/White_moqalqal.jpeg'
import Red_moqalqal_01 from '../images/Menu_Images/Main_Dishes/Red_moqalqal_01.jpeg'

import Pepsi from '../images/Menu_Images/Drinks/Pepsi.png'
import pepsi_diet from '../images/Menu_Images/Drinks/pepsi_diet.png'
import himdyat from '../images/Menu_Images/Drinks/himdyat.png'
import mirinda_orange from '../images/Menu_Images/Drinks/mirinda_orange.png'
import seven_up from '../images/Menu_Images/Drinks/7up.jpg'
import seven_up_diet from '../images/Menu_Images/Drinks/7up_diet.jpg'
import mountain_dew from '../images/Menu_Images/Drinks/mountain_dew.png'
import shani from '../images/Menu_Images/Drinks/shani.png'
import watter from '../images/Menu_Images/Drinks/watter.png'

import twitter_Icon from '../images/twitter_Icon.svg'
import instagram_Icon from '../images/instagram_Icon.svg'
import tiktok_Icon from '../images/tiktok_Icon.svg'
import whatsapp_Icon from '../images/whatsapp_Icon.svg'
import phone_Icon from '../images/phone_Icon.svg'

const AllCakesItems = [

    // {
    //     id: '01',
    //     categoryNameAR: 'الأطباق الرئيسية',
    //     categoryNameEN: 'Main Dishes',
    //     categoryItems: [
    //         {
    //             id: "01",
    //             img: zigni_with_rice,
    //             itemNameAR: "زقني مع رز",
    //             itemNameEN: "Zigni with rice",
    //             descriptionAR: "يخفف مساج العلاج الطبيعي الآلام والتوتر، ويعيد التوازن للجسم من خلال حركات دقيقة مخصصة لتقليل التعب وتحسين الحركة.",
    //             descriptionEN: "This therapeutic massage relieves pain and tension, restoring balance with precise movements designed to reduce fatigue and improve mobility.",
    //             duration: "45",
    //             price: "150"
    //         },
    //         {
    //             id: "02",
    //             img: zigni_with_white_lahloh,
    //             itemNameAR: "زقني مع لحوح ابيض",
    //             itemNameEN: "Zigni with white lahouh",
    //             descriptionAR: "يعمل مساج الاسترخاء على تهدئة العقل والجسم من خلال تقنيات تدليك خفيفة تساعد على تخفيف التوتر وتمنحك شعوراً بالراحة.",
    //             descriptionEN: "A relaxing massage that soothes the mind and body through gentle techniques, helping to relieve stress and providing a sense of calm and comfort.",
    //             duration: "45",
    //             price: "130"
    //         },
    //         {
    //             id: "03",
    //             img: zigni_with_blue_lahloh,
    //             itemNameAR: "زقني مع لحوح طيف",
    //             itemNameEN: "Zigni with blue lahouh",
    //             descriptionAR: "يحفز مساج التصريف اللمفاوي الدورة اللمفاوية لتحسين التصريف وتقليل الانتفاخ، مما يعزز الشعور بالصحة والحيوية.",
    //             descriptionEN: "The lymphatic massage stimulates lymph circulation to improve drainage and reduce swelling, promoting a feeling of health and vitality.",
    //             duration: "45",
    //             price: "250"
    //         },
    //         {
    //             id: "04",
    //             img: White_moqalqal,
    //             itemNameAR: "مقلقل ابيض",
    //             itemNameEN: "White moqalqal",
    //             descriptionAR: "يعزز مساج الأكواب الهوائية الدورة الدموية ويخفف التوتر العضلي، مما يساهم في تحسين الاسترخاء وتخفيف الألم.",
    //             descriptionEN: "The air cup massage enhances blood circulation and relieves muscle tension, promoting relaxation and easing discomfort.",
    //             duration: "45",
    //             price: "170"
    //         },
    //         {
    //             id: "05",
    //             img: Red_moqalqal_01,
    //             itemNameAR: "مقلقل احمر",
    //             itemNameEN: "Red moqalqal",
    //             descriptionAR: "يخفف مساج الحمل من آلام العضلات ويمنح الراحة، بلمسات لطيفة تناسب الأم والجنين لزيادة الاسترخاء وتخفيف التوتر.",
    //             descriptionEN: "This pregnancy massage eases muscle pain with gentle touches for both mother and baby, promoting relaxation and relieving stress.",
    //             duration: "45",
    //             price: "200"
    //         }
    //     ]
    // },

    {
        id: "01",
        categoryNameAR: "الأطباق الرئيسية",
        categoryNameEN: "Main Dishes",
        categoryItems: [
            {
                id: "01",
                img: zigni_with_rice,
                itemNameAR: "زقني مع رز",
                itemNameEN: "Zigni with rice",
                descriptionAR: "زقني مطبوخ ببراعة مع مزيج من البهارات الإثيوبية يقدم مع الأرز الطازج لتجربة طعام لا تُنسى.",
                descriptionEN: "Zigni perfectly cooked with a blend of Ethiopian spices, served with fresh rice for an unforgettable dining experience.",
                duration: "45",
                price: "65"
            },
            {
                id: "02",
                img: zigni_with_white_lahloh,
                itemNameAR: "زقني مع لحوح ابيض",
                itemNameEN: "Zigni with white lahouh",
                descriptionAR: "زقني الغني بالنكهات يقدم مع لحوح أبيض طري ومميز، ليأخذك إلى قلب إثيوبيا.",
                descriptionEN: "Flavorful Zigni served with soft and distinctive white lahouh, taking you to the heart of Ethiopia.",
                duration: "45",
                price: "55"
            },
            {
                id: "03",
                img: zigni_with_blue_lahloh,
                itemNameAR: "زقني مع لحوح طيف",
                itemNameEN: "Zigni with blue lahouh",
                descriptionAR: "زقني اللذيذ يقدم مع لحوح طيف ذو طعم فريد، ليمنحك إحساسًا بالتقاليد الإثيوبية الأصيلة.",
                descriptionEN: "Delicious Zigni served with blue lahouh of unique taste, offering a sense of authentic Ethiopian traditions.",
                duration: "45",
                price: "60"
            },
            {
                id: "04",
                img: White_moqalqal,
                itemNameAR: "مقلقل ابيض",
                itemNameEN: "White moqalqal",
                descriptionAR: "طبق مقلقل أبيض يقدم بمذاق رائع ونكهة تذكرك بوجبات البيت الإثيوبية الدافئة.",
                descriptionEN: "White Moqalqal dish served with amazing flavor, reminiscent of warm Ethiopian home meals.",
                duration: "45",
                price: "45"
            },
            {
                id: "05",
                img: Red_moqalqal_01,
                itemNameAR: "مقلقل احمر",
                itemNameEN: "Red moqalqal",
                descriptionAR: "مقلقل أحمر معد بمزيج مثالي من التوابل الإثيوبية التقليدية لتجربة طعام مميزة.",
                descriptionEN: "Red Moqalqal prepared with a perfect blend of traditional Ethiopian spices for a distinctive dining experience.",
                duration: "45",
                price: "45"
            }
        ]
    },

    {
        id: '02',
        categoryNameAR: 'المشروبات',
        categoryNameEN: 'Drinks',
        categoryItems: [
            {
                id: "01",
                img: Pepsi,
                itemNameAR: "بيبسي",
                itemNameEN: "Pepsi",
                // descriptionAR: "يخفف مساج العلاج الطبيعي الآلام والتوتر، ويعيد التوازن للجسم من خلال حركات دقيقة مخصصة لتقليل التعب وتحسين الحركة.",
                // descriptionEN: "This therapeutic massage relieves pain and tension, restoring balance with precise movements designed to reduce fatigue and improve mobility.",
                duration: "45",
                price: "3"
            },
            {
                id: "02",
                img: pepsi_diet,
                itemNameAR: "بيبسي دايت",
                itemNameEN: "Pepsi Diet",
                // descriptionAR: "يعمل مساج الاسترخاء على تهدئة العقل والجسم من خلال تقنيات تدليك خفيفة تساعد على تخفيف التوتر وتمنحك شعوراً بالراحة.",
                // descriptionEN: "A relaxing massage that soothes the mind and body through gentle techniques, helping to relieve stress and providing a sense of calm and comfort.",
                duration: "45",
                price: "3"
            },
            {
                id: "03",
                img: himdyat,
                itemNameAR: "حمضيات",
                itemNameEN: "Mirinda Citrus",
                // descriptionAR: "يحفز مساج التصريف اللمفاوي الدورة اللمفاوية لتحسين التصريف وتقليل الانتفاخ، مما يعزز الشعور بالصحة والحيوية.",
                // descriptionEN: "The lymphatic massage stimulates lymph circulation to improve drainage and reduce swelling, promoting a feeling of health and vitality.",
                duration: "45",
                price: "3"
            },
            {
                id: "04",
                img: mirinda_orange,
                itemNameAR: "مريندا برتقال",
                itemNameEN: "Mirinda Orange",
                // descriptionAR: "يعزز مساج الأكواب الهوائية الدورة الدموية ويخفف التوتر العضلي، مما يساهم في تحسين الاسترخاء وتخفيف الألم.",
                // descriptionEN: "The air cup massage enhances blood circulation and relieves muscle tension, promoting relaxation and easing discomfort.",
                duration: "45",
                price: "3"
            },
            {
                id: "05",
                img: seven_up,
                itemNameAR: "سفن آب",
                itemNameEN: "7up",
                // descriptionAR: "يخفف مساج الحمل من آلام العضلات ويمنح الراحة، بلمسات لطيفة تناسب الأم والجنين لزيادة الاسترخاء وتخفيف التوتر.",
                // descriptionEN: "This pregnancy massage eases muscle pain with gentle touches for both mother and baby, promoting relaxation and relieving stress.",
                duration: "45",
                price: "3"
            },
            {
                id: "06",
                img: seven_up_diet,
                itemNameAR: "سفن آب دايت",
                itemNameEN: "7up Diet",
                // descriptionAR: "يخفف مساج الحمل من آلام العضلات ويمنح الراحة، بلمسات لطيفة تناسب الأم والجنين لزيادة الاسترخاء وتخفيف التوتر.",
                // descriptionEN: "This pregnancy massage eases muscle pain with gentle touches for both mother and baby, promoting relaxation and relieving stress.",
                duration: "45",
                price: "3"
            },
            {
                id: "07",
                img: mountain_dew,
                itemNameAR: "ديو",
                itemNameEN: "Mountain Dew",
                // descriptionAR: "يخفف مساج الحمل من آلام العضلات ويمنح الراحة، بلمسات لطيفة تناسب الأم والجنين لزيادة الاسترخاء وتخفيف التوتر.",
                // descriptionEN: "This pregnancy massage eases muscle pain with gentle touches for both mother and baby, promoting relaxation and relieving stress.",
                duration: "45",
                price: "3"
            },
            {
                id: "08",
                img: shani,
                itemNameAR: "شاني",
                itemNameEN: "Shani",
                // descriptionAR: "يخفف مساج الحمل من آلام العضلات ويمنح الراحة، بلمسات لطيفة تناسب الأم والجنين لزيادة الاسترخاء وتخفيف التوتر.",
                // descriptionEN: "This pregnancy massage eases muscle pain with gentle touches for both mother and baby, promoting relaxation and relieving stress.",
                duration: "45",
                price: "3"
            },
            {
                id: "09",
                img: watter,
                itemNameAR: "ماء",
                itemNameEN: "Watter",
                // descriptionAR: "يخفف مساج الحمل من آلام العضلات ويمنح الراحة، بلمسات لطيفة تناسب الأم والجنين لزيادة الاسترخاء وتخفيف التوتر.",
                // descriptionEN: "This pregnancy massage eases muscle pain with gentle touches for both mother and baby, promoting relaxation and relieving stress.",
                duration: "45",
                price: "2"
            }
        ]
    },

]

const SocialMediaIcons = [
    {
        icon: instagram_Icon,
        link: "https://www.instagram.com/lumina_spa1"
    },
    {
        icon: twitter_Icon,
        link: "https://twitter.com/lumina_spa1"
    },
    {
        icon: tiktok_Icon,
        link: "https://www.tiktok.com/@lumina_spa1"
    },
    {
        icon: whatsapp_Icon,
        link: "https://wa.me/966570075668"
    },
    {
        icon: phone_Icon,
        link: "tel:+966570075668"
    },
]

const couponCodes = [
    { name: 'Ra4195', percentage: 20, minmumPrice: 200, DisplayedTextEN: "", DisplayedTextAR: "لأنك جاية من طرف روان", },
    { name: 'As11', percentage: 20, minmumPrice: 200, DisplayedTextEN: "", DisplayedTextAR: "لأنك جاية من طرف أحمد", },
    { name: 'N247', percentage: 20, minmumPrice: 200, DisplayedTextEN: "", DisplayedTextAR: "لأنك جاية من طرف ", },
    { name: 'MD4176', percentage: 20, minmumPrice: 200, DisplayedTextEN: "", DisplayedTextAR: "لأنك جاية من طرف محمد", },
    { name: 'A5734', percentage: 20, minmumPrice: 200, DisplayedTextEN: "", DisplayedTextAR: "خصم خاص من سوقو 💜", },
    { name: 'SH_Cakery30', percentage: 30, minmumPrice: 250, DisplayedTextEN: "", DisplayedTextAR: "خصم خاص من شهد كيكري 💜", },
    { name: 'SH_Cakery20', percentage: 20, minmumPrice: 170, DisplayedTextEN: "", DisplayedTextAR: "خصم خاص من شهد كيكري 💜", },

];

// Neighborhood and delivery fee logic
const neighborhoods =
    [
        {
            id: "01",
            nameEN: "Safa",
            nameAR: "الصفا",
            fee: 20
        },
        {
            id: "02",
            nameEN: "Marwah",
            nameAR: "المروة",
            fee: 25
        },
        {
            id: "03",
            nameEN: "Ar Rabwa",
            nameAR: "الربوة",
            fee: 30
        },
        {
            id: "04",
            nameEN: "Al Nuzha",
            nameAR: "النزهة",
            fee: 25
        },
        {
            id: "05",
            nameEN: "Al Naeem",
            nameAR: "النعيم",
            fee: 35
        },
        {
            id: "06",
            nameEN: "Al Salamah",
            nameAR: "السلامة",
            fee: 30
        },
        {
            id: "07",
            nameEN: "Al Mohammadiyyah",
            nameAR: "المحمدية",
            fee: 35
        },
        {
            id: "08",
            nameEN: "Al Murjan",
            nameAR: "المرجان",
            fee: 40
        },
        {
            id: "09",
            nameEN: "Bryman",
            nameAR: "بريمان",
            fee: 30
        },
        {
            id: "10",
            nameEN: "Al Hamadaniyyah",
            nameAR: "الحمدانية",
            fee: 35
        },
        {
            id: "11",
            nameEN: "Al Kauthar",
            nameAR: "الكوثر",
            fee: 35
        },
        {
            id: "12",
            nameEN: "Al-Salehiyah",
            nameAR: "الصالحية",
            fee: 40
        },
        {
            id: "13",
            nameEN: "Al Falah",
            nameAR: "الفلاح",
            fee: 40
        },
        {
            id: "14",
            nameEN: "Al Rahmanyah",
            nameAR: "الرحمانية",
            fee: 40
        },
        {
            id: "15",
            nameEN: "Al Bashaer",
            nameAR: "البشاير",
            fee: 45
        },
        {
            id: "16",
            nameEN: "Taiba",
            nameAR: "طيبة",
            fee: 40
        },
        {
            id: "17",
            nameEN: "Al Firdous",
            nameAR: "الفردوس",
            fee: 50
        },
        {
            id: "18",
            nameEN: "Al Yaqoot",
            nameAR: "الياقوت",
            fee: 50
        },
        {
            id: "19",
            nameEN: "Al Zummrad",
            nameAR: "الزمرد",
            fee: 55
        },
        {
            id: "20",
            nameEN: "Al Riyadh",
            nameAR: "الرياض",
            fee: 45
        },
        {
            id: "21",
            nameEN: "Al Faisaliyyah",
            nameAR: "الفيصلية",
            fee: 25
        },
        {
            id: "22",
            nameEN: "Al Samer",
            nameAR: "السامر",
            fee: 25
        },
        {
            id: "23",
            nameEN: "Al Ajwad",
            nameAR: "الأجواد",
            fee: 25
        },
        {
            id: "24",
            nameEN: "Al Montazah",
            nameAR: "المنتزه",
            fee: 30
        },
        {
            id: "25",
            nameEN: "Al Tawfiq",
            nameAR: "التوفيق",
            fee: 30
        },
        {
            id: "26",
            nameEN: "Al Waha",
            nameAR: "الواحة",
            fee: 30
        },
        {
            id: "27",
            nameEN: "Al-Rehab",
            nameAR: "الرحاب",
            fee: 25
        },
        {
            id: "28",
            nameEN: "Al Taiseer",
            nameAR: "التيسير",
            fee: 30
        },
        {
            id: "29",
            nameEN: "Mishrifah",
            nameAR: "مشرفة",
            fee: 30
        },
        {
            id: "30",
            nameEN: "Al Sharafeyah",
            nameAR: "الشرفية",
            fee: 30
        },
        {
            id: "31",
            nameEN: "Al Andalus",
            nameAR: "الأندلس",
            fee: 35
        },
        {
            id: "32",
            nameEN: "Al-Ruwais",
            nameAR: "الرويس",
            fee: 30
        },
        {
            id: "33",
            nameEN: "An Nahdah",
            nameAR: "النهضة",
            fee: 35
        },
        {
            id: "34",
            nameEN: "Bani Malik",
            nameAR: "بني مالك",
            fee: 30
        },
        {
            id: "35",
            nameEN: "An Naseem",
            nameAR: "النسيم",
            fee: 30
        },
        {
            id: "36",
            nameEN: "Al Warood",
            nameAR: "الورود",
            fee: 30
        },
        {
            id: "37",
            nameEN: "Al Sulaymaniyah",
            nameAR: "السليمانية",
            fee: 30
        },
        {
            id: "38",
            nameEN: "Al Fayha'a",
            nameAR: "الفيحاء",
            fee: 35
        },
        {
            id: "39",
            nameEN: "Abruq Ar Rughamah",
            nameAR: "أبرق الرغامة",
            fee: 35
        },
        {
            id: "40",
            nameEN: "Al-Balad",
            nameAR: "البلد",
            fee: 35
        },
        {
            id: "41",
            nameEN: "Al Jami`ah",
            nameAR: "الجامعة",
            fee: 35
        },
        {
            id: "42",
            nameEN: "Quwaizah",
            nameAR: "قويزة",
            fee: 35
        },
        {
            id: "43",
            nameEN: "Mada'en Al-Fahd",
            nameAR: "مدائن الفهد",
            fee: 35
        },
        {
            id: "44",
            nameEN: "Al-Nazlah Al-Yamaniyah",
            nameAR: "النزهة اليمانية",
            fee: 35
        },
        {
            id: "45",
            nameEN: "Al Mahjar",
            nameAR: "المهجر",
            fee: 40
        },
        {
            id: "46",
            nameEN: "Al-Rawabi",
            nameAR: "الروابي",
            fee: 35
        },
        {
            id: "47",
            nameEN: "Al Muntazahat",
            nameAR: "المنتزهات",
            fee: 35
        },
        {
            id: "48",
            nameEN: "Al Adel",
            nameAR: "العدل",
            fee: 35
        },
        {
            id: "49",
            nameEN: "Alfadel",
            nameAR: "الفضل",
            fee: 40
        },
        {
            id: "50",
            nameEN: "Al-Wazeeriyah",
            nameAR: "الوزيرية",
            fee: 35
        },
        {
            id: "51",
            nameEN: "Al Jawharah",
            nameAR: "الجوهرة",
            fee: 35
        },
        {
            id: "52",
            nameEN: "Alajaweed",
            nameAR: "الأجاويد",
            fee: 40
        },
        {
            id: "53",
            nameEN: "Al Sanabel",
            nameAR: "السنابل",
            fee: 40
        },
        {
            id: "54",
            nameEN: "Almahameed",
            nameAR: "المحاميد",
            fee: 45
        }
    ];

export { SocialMediaIcons, AllCakesItems, couponCodes, neighborhoods }