import React, { useEffect, useState } from 'react'
import cookies from "js-cookie";
import { useTranslation } from 'react-i18next';
import cart_Icon_button from '../images/cart_Icon_button.png'
import whatsapp_botton_icon from '../images/whatsapp_icon.png'
import hi_five from '../images/hi_five.svg'
import { AllCakesItems } from './Data';
import { useCart } from '../context/CartContext';

const ExploreProjects = () => {
    const currentLanguageCode = cookies.get('i18next');
    const { t } = useTranslation();
    const { cart, addToCart, removeItem } = useCart();
    const [cartItems, setCartItems] = useState([]);

    useEffect(() => {
        setCartItems(cart.map(item => item.itemNameEN));
    }, [cart]);

    const handleCartButtonClick = (card) => {
        if (cartItems.includes(card.itemNameEN)) {
            const itemIndex = cart.findIndex((cartItem) => cartItem.itemNameEN === card.itemNameEN);
            removeItem(itemIndex);
        } else {
            addToCart(card);
        }
    };

    return (
        <>
            {/* Mobile and Tablet */}
            <div className='mb-20 mt-20'>
                <div className='md:hidden flex justify-center gap-2 text-3xl font-bold'>
                    <h1>{t('Explor_Our_Services')}</h1>
                    <img src={hi_five} alt='hi_five' />
                </div>

                {AllCakesItems.map((item) => (
                    <div key={item.id}>
                        {currentLanguageCode === 'en' ?
                            <h1 className='ms-3 text-[24px] font-bold mt-12'>{item.categoryNameEN}</h1>
                            :
                            <h1 className='ms-3 text-[24px] font-bold mt-12'>{item.categoryNameAR}</h1>
                        }

                        {/* For Mobile (scrollable) */}
                        <div className="md:hidden overflow-x-scroll ps-3 py-2 flex">
                            {item.categoryItems.map((card) => (
                                <div key={card.id} className='group flex-none w-[250px] shadow-md me-4 rounded-[20px]'>
                                    <div className='flex flex-col items-center justify-between h-full'>
                                        <img className='w-[250px] h-[250px] rounded-t-[20px]' src={card.img} alt='product_Image_01' />
                                        <h1 className='font-bold text-[14px] mt-4'>{currentLanguageCode === 'en' ? card.itemNameEN : card.itemNameAR}</h1>
                                        <h1 className='text-[#494949] text-[12px]  mx-3 my-[10px]'>{currentLanguageCode === 'en' ? card.descriptionEN : card.descriptionAR}</h1>
                                        <h1 className='text-[12px] mb-4'>
                                            {t('Starts_from')} <span className='text-[#7B250C] font-bold'> {card.price} {t("currency")}</span>
                                        </h1>

                                        <div className='flex justify-between w-full px-3 mb-3 gap-6 mx-3'>
                                            <button
                                                onClick={() => handleCartButtonClick(card)}
                                                className='w-full group-active:animate-bounce'>
                                                <div className={`py-[5px] px-[12px] rounded-[11px] text-white flex justify-center gap-5 ${cartItems.includes(card.itemNameEN) ? 'bg-gray-400' : 'bg-black'}`}>
                                                    <h1 className='text-[14px]'>{cartItems.includes(card.itemNameEN) ? t('Remove_From_Cart') : t('Add_to_Cart')}</h1>
                                                    <img className='w-5 h-5' src={cart_Icon_button} alt='cart_Icon_button' />
                                                </div>
                                            </button>
                                            {/* <button
                                                onClick={() => window.open(`https://wa.me/+966570075668?text=${currentLanguageCode === 'en' ? "I want to ask about " : "أرغب في الاستفسار عن "}${currentLanguageCode === 'en' ? card.itemNameEN : card.itemNameAR}`)}
                                                className='w-full group-active:animate-upDown'>
                                                <div className='bg-[#7B250C] py-[5px] px-[12px] rounded-[11px] text-white flex justify-between'>
                                                    <h1 className='text-[14px]'>{t('Whats')}</h1>
                                                    <img className='w-5 h-5' src={whatsapp_botton_icon} alt='whatsapp_botton_icon' />
                                                </div>
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* For Tablet and Desktop */}
                        <div
                            className="hidden md:flex lg:flex xl:flex gap-6 px-6 py-4 overflow-x-scroll overflow-y-hidden no-scrollbar"
                        >
                            {item.categoryItems.map((card, index) => (
                                <div key={card.id} className='group shadow-md flex-none rounded-[20px] w-[250px]'>
                                    <div className='flex flex-col items-center justify-between h-fit w-fit'>
                                        <img className='w-[250px] h-[250px] rounded-t-[20px]' src={card.img} alt='product_Image_01' />
                                        <h1 className='font-bold text-[14px] mt-4'>
                                            {currentLanguageCode === 'en' ? card.itemNameEN : card.itemNameAR}
                                        </h1>
                                        <h1 className='text-[#494949] text-[12px] mx-3 my-[10px]'>
                                            {currentLanguageCode === 'en' ? card.descriptionEN : card.descriptionAR}
                                        </h1>
                                        <h1 className='text-[12px] mb-4'>
                                            {t('Starts_from')} <span className='text-[#7B250C] font-bold'> {card.price} {t("currency")}</span>
                                        </h1>

                                        <div className='flex justify-between w-full px-3 mb-3 gap-6 mx-3'>
                                            <button
                                                onClick={() => handleCartButtonClick(card)}
                                                className='w-full group-active:animate-bounce'
                                            >
                                                <div className={`py-[5px] px-[12px] rounded-[11px] text-white flex justify-center gap-4 ${cartItems.includes(card.itemNameEN) ? 'bg-gray-400' : 'bg-black'}`}>
                                                    <h1 className='text-[14px]'>
                                                        {cartItems.includes(card.itemNameEN) ? t('Remove_From_Cart') : t('Add_to_Cart')}
                                                    </h1>
                                                    <img className='w-5 h-5' src={cart_Icon_button} alt='cart_Icon_button' />
                                                </div>
                                            </button>
                                            {/* <button
                                                onClick={() => window.open(`https://wa.me/+966570075668?text=${currentLanguageCode === 'en' ? "I want to ask about " : "أرغب في الاستفسار عن "}${currentLanguageCode === 'en' ? card.itemNameEN : card.itemNameAR}`)}
                                                className='w-full group-active:animate-upDown'
                                            >
                                                <div className='bg-[#7B250C] py-[5px] px-[12px] rounded-[11px] text-white flex justify-between'>
                                                    <h1 className='text-[14px]'>{t('Whats')}</h1>
                                                    <img className='w-5 h-5' src={whatsapp_botton_icon} alt='whatsapp_botton_icon' />
                                                </div>
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                ))}
            </div>
        </>
    )
}

export default ExploreProjects;
