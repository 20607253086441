import React, { useState } from 'react'
import i18next from "i18next";
import logo from '../images/logo.jpg'
import down_arrow from '../images/down_arrow.svg'
import cookies from "js-cookie";
import { useCart } from '../context/CartContext';

const Hero = () => {
    const { cart } = useCart(); // Use the cart context
    const currentLanguageCode = cookies.get('i18next')
    const [language, setLanguage] = useState(currentLanguageCode); // State to handle re-render

    const changeLanguage = (lng) => {
        i18next.changeLanguage(lng);
        cookies.set('i18next', lng); // Ensure cookie updates
        setLanguage(lng); // Trigger a re-render by updating state
    };

    return (
        <>
            {/* Mobile */}
            <div className='sticky top-2 flex justify-between mx-2 md:hidden'>
                <div className='top-2 start-2 flex justify-end mt-2'>
                    {currentLanguageCode === 'en' ?
                        <button className="flex justify-between border-[1px] w-full py-1 px-2 rounded-lg my-1" onClick={() => changeLanguage('ar')}>
                            <div className="flex justify-center mx-2">
                                <h1>AR</h1>
                            </div>
                        </button>
                        :
                        <button className="flex justify-between border-[1px] w-full py-1 px-2 rounded-lg my-1" onClick={() => changeLanguage('en')}>
                            <div className="flex justify-center mx-2">
                                <h1>EN</h1>
                            </div>
                        </button>
                    }
                </div>

                <div className='flex justify-start ps-2 pt-5 pe-2'>
                    <a href="/cart">
                        {cart.length > 0 && (
                            <span className="bg-[#7B250C] end-2 top-1 absolute text-white rounded-full text-xs px-2">
                                {cart.length}
                            </span>
                        )}
                        <svg className='end-2 top-4 absolute fill-mainBrown' xmlns="http://www.w3.org/2000/svg" width="29.248" height="28.8" viewBox="0 0 29.248 28.8">
                            <path id="md-cart" d="M11.925,26.55a2.925,2.925,0,1,0,2.925,2.925A2.933,2.933,0,0,0,11.925,26.55ZM3.375,3.6V6.525H6.3L11.565,17.19l-2.2,3.583A2.808,2.808,0,0,0,9,22.163c0,1.958,1.338,2.925,3.152,2.925h17.1V22.275H12.508a.346.346,0,0,1-.366-.366,1.826,1.826,0,0,1,.155-.366l1.461-2.306h10.9A2.869,2.869,0,0,0,27.212,17.7l5.265-9.056a1.709,1.709,0,0,0,.146-.731,1.406,1.406,0,0,0-1.463-1.39H9.518L8.128,3.6Zm22.95,22.95a2.925,2.925,0,1,0,2.925,2.925A2.933,2.933,0,0,0,26.325,26.55Z" transform="translate(-3.375 -3.6)" />
                        </svg>
                    </a>
                </div>
            </div>

            {/* Mobile content */}
            <div className='md:hidden'>
                <div className='flex justify-center pt-10'>
                    {/* <img src={currentLanguageCode === 'en' ? logo_En : logo_Ar} alt='logo' /> */}
                    <img src={logo} alt='logo' />
                </div>
                <div className='flex justify-center mt-10'>
                    <img className='animate-upDown' src={down_arrow} alt='down_arrow' />
                </div>
            </div>

            {/* Tablet and Desktop content */}
            <div className='hidden relative md:flex justify-between items-center'>
                {/* Cart Icon - Fixed Left */}
                <div className='fixed top-4 end-4 z-50'>
                    <a href="/cart" className='relative'>
                        {cart.length > 0 && (
                            <span className="bg-[#7B250C] text-white rounded-full text-xs px-2 absolute -top-2 -right-2">
                                {cart.length}
                            </span>
                        )}
                        <svg className='fill-mainBrown w-8 h-8' xmlns="http://www.w3.org/2000/svg" width="29.248" height="28.8" viewBox="0 0 29.248 28.8">
                            <path id="md-cart" d="M11.925,26.55a2.925,2.925,0,1,0,2.925,2.925A2.933,2.933,0,0,0,11.925,26.55ZM3.375,3.6V6.525H6.3L11.565,17.19l-2.2,3.583A2.808,2.808,0,0,0,9,22.163c0,1.958,1.338,2.925,3.152,2.925h17.1V22.275H12.508a.346.346,0,0,1-.366-.366,1.826,1.826,0,0,1,.155-.366l1.461-2.306h10.9A2.869,2.869,0,0,0,27.212,17.7l5.265-9.056a1.709,1.709,0,0,0,.146-.731,1.406,1.406,0,0,0-1.463-1.39H9.518L8.128,3.6Zm22.95,22.95a2.925,2.925,0,1,0,2.925,2.925A2.933,2.933,0,0,0,26.325,26.55Z" transform="translate(-3.375 -3.6)" />
                        </svg>
                    </a>
                </div>

                {/* Logo - Centered */}
                <div className='flex justify-center w-full items-center '>
                    {/* <img className='w-1/6 mt-10' src={language === 'en' ? logo_En : logo_Ar} alt='logo' /> */}
                    <img className='w-1/6 mt-10 rounded-[20px]' src={logo} alt='logo' />
                </div>


                {/* Language Button - Fixed Right */}
                <div className='fixed top-4 start-4 z-50'>
                    {language === 'en' ?
                        <button className="border-[1px] py-2 px-4 rounded-lg" onClick={() => changeLanguage('ar')}>
                            <h1>AR</h1>
                        </button>
                        :
                        <button className="border-[1px] py-2 px-4 rounded-lg" onClick={() => changeLanguage('en')}>
                            <h1>EN</h1>
                        </button>
                    }
                </div>
            </div>

        </>
    )
}

export default Hero;
