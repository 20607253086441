import React from 'react'
// import footerLogoEn from '../images/logo_En.png'
// import footerLogoAr from '../images/logo_Ar.png'
import footerLogo from '../images/logo.jpg'
import { SocialMediaIcons } from './Data'
import { useTranslation } from 'react-i18next';
import cookies from "js-cookie";

const Footer = () => {
    const { t } = useTranslation();
    const currentLanguageCode = cookies.get('i18next');

    return (
        <>
            {/* Mobile */}
            <div className='md:hidden'>
                <div className='rounded-t-[20px] bg-[#7B250C] mt-20 text-white flex flex-col'>
                    <div className='flex flex-col  items-center'>
                        {/* <img className='' src={currentLanguageCode === 'en' ? footerLogoEn : footerLogoAr} alt='footerLogo' /> */}
                        <img className='w-[200px]' src={footerLogo} alt='footerLogo' />
                        <h1 className='text-[24px] text-center me-4 font-bold leading-tight'>{t('shahad_promise')}</h1>
                    </div>

                    <div className='flex justify-center gap-6 my-8'>
                        {SocialMediaIcons.map((item, index) => (
                            <a key={index} href={item.link}>
                                <img src={item.icon} alt={item.icon} />
                            </a>
                        ))}
                    </div>

                    <hr className='mb-3' />

                    <div className='flex justify-center mb-8'>
                        <h1>{t('Copywrite')}</h1>
                    </div>
                </div>
            </div>

            {/* Desktop and Tablet */}
            <div className='hidden md:flex flex-col items-center bg-[#7B250C] text-white mt-20'>
                <div className='container mx-auto py-12 px-6'>
                    <div className='flex justify-between items-center'>
                        {/* <img className='w-1/4' src={currentLanguageCode === 'en' ? footerLogoEn : footerLogoAr} alt='footerLogo' /> */}
                        <img className='h-[200px] ' src={footerLogo} alt='footerLogo' />
                        <h1 className='text-[36px] font-bold'>{t('shahad_promise')}</h1>
                    </div>

                    <div className='flex justify-center gap-8 my-12'>
                        {SocialMediaIcons.map((item, index) => (
                            <a key={index} href={item.link} className='hover:opacity-80 transition-opacity'>
                                <img className='w-8 h-8' src={item.icon} alt={item.icon} />
                            </a>
                        ))}
                    </div>

                    <hr className='mb-6' />

                    <div className='flex justify-center'>
                        <h1 className='text-[18px]'>{t('Copywrite')}</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer
